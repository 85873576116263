export default {
    inputText: `
    383책먹는 마법사-28.pdf 的批注摘要。


20220518_194550_1107

笔记 [前往页面 1]: 西爾維婭把<span style="color: red;" contains-block>演示效果，开发中。。。</span>的心關起來了。


20220518_194550_1108

笔记 [前往页面 2]: 冰柱生長<span style="background-color: yellow;" not-contains-dictionary>演示效果,开发中...</span>!
(Icicle Grow)

笔记 [前往页面 2]: 嚓啊


20220518_194550_1109

笔记 [前往页面 3]: 忌妒、憎恨、誤會、他人的不理睬，

笔记 [前往页面 3]: 在她因為其他人對她投以的情感受傷之前，她放棄了主動接近他人。


20220518_194550_1110

笔记 [前往页面 4]: 唰啊啊

笔记 [前往页面 4]: 地面波!
(Earth Wave)


20220518_194550_1111

笔记 [前往页面 5]: 咚咚咚


20220518_194550_1112

笔记 [前往页面 6]: 哐哐


20220518_194550_1113

笔记 [前往页面 7]: 她所建築的那一道心牆...


20220518_194550_1114

笔记 [前往页面 8]: 也在不知不覺中，成為了阻擋她刻上5環魔法的那一道「牆」。


20220518_194550_1115

笔记 [前往页面 9]: 但是，現在...

笔记 [前往页面 9]: 這個人不討厭我，也不害怕我。


20220518_194550_1116

笔记 [前往页面 10]: 而且還一直使出讓我感到驚訝的魔法。

笔记 [前往页面 10]: 這種感覺是什麼?我從來沒有感受過...


20220518_194550_1117

笔记 [前往页面 11]: 我想要更了解他!


20220518_194550_1118

笔记 [前往页面 12]: 哐

笔记 [前往页面 12]: 咿

笔记 [前往页面 12]: 我想要贏過他!

笔记 [前往页面 12]: 咿


20220518_194550_1119

笔记 [前往页面 13]: 咿

笔记 [前往页面 13]: 咿


20220518_194550_1120

笔记 [前往页面 14]: 咻嗚


20220518_194550_1121

笔记 [前往页面 15]: 西爾維婭...


20220518_194550_1122

笔记 [前往页面 16]: 妳終於要打破4環的牆了...!


20220518_194550_1123

笔记 [前往页面 17]: 第28話

笔记 [前往页面 17]: 我想要贏!
（放大+粗體）


20220518_194550_1126

笔记 [前往页面 20]: 嗯，我也想贏。


20220518_194550_1127

笔记 [前往页面 21]: 進入學院之後，我看著無數人離開的背影。


20220518_194550_1129

笔记 [前往页面 23]: 但是，我遇見了格拉特尼，我已經不一樣了。


20220518_194550_1130

笔记 [前往页面 24]: 這是最後一次了。
（字體放大）


20220518_194550_1131

笔记 [前往页面 25]: 追逐著某個人背影的這件事!
（字體放大＋變色）


20220518_194550_1132

笔记 [前往页面 26]: 飄浮


20220518_194550_1133

笔记 [前往页面 27]: 2環魔法，懸浮。
(Levitation)


20220518_194550_1134

笔记 [前往页面 28]: 雖然只是單純地浮在空中，

笔记 [前往页面 28]: 但是對於避開地面攻擊非常有效!


20220518_194550_1135

笔记 [前往页面 29]: 嗖嗖


20220518_194550_1136

笔记 [前往页面 30]: 冰盾?


20220518_194550_1137

笔记 [前往页面 31]: 剛才擋下燃燒砲彈的就是冰盾嗎?

笔记 [前往页面 31]: 唰啦


20220518_194550_1138

笔记 [前往页面 32]: 唰啦拉


20220518_194550_1139

笔记 [前往页面 33]: 哐

笔记 [前往页面 33]: 哐


20220518_194550_1140

笔记 [前往页面 34]: 看樣子她動了點腦筋，

笔记 [前往页面 34]: 一般的魔彈的確穿不過那個護盾。


20220518_194550_1141

笔记 [前往页面 35]: 而且我也看不清楚她的確切位置，很難瞄準...

笔记 [前往页面 35]: 她是要誘導我與她正面交鋒。
（字體放大＋變色）

笔记 [前往页面 35]: 這也就代表，她還留有底牌。


20220518_194550_1142

笔记 [前往页面 36]: 冰盾只不過是她用來爭取時間的手法。


20220518_194550_1143

笔记 [前往页面 37]: 好啊，我接受。


20220518_194550_1144

笔记 [前往页面 38]: 正面交鋒!


20220518_194550_1145

笔记 [前往页面 39]: 讓我們掀開彼此的底牌吧!
（字體放大＋變色）

笔记 [前往页面 39]: 密特拉。


20220518_194550_1146

笔记 [前往页面 40]: 咻嗚嗚


20220518_194550_1148

笔记 [前往页面 42]: 大地的巨人啊，我命令你擲石子...

笔记 [前往页面 42]: 啪啊啊啊


20220518_194550_1149

笔记 [前往页面 43]: 回應我的呼喚，顯現在此吧!

笔记 [前往页面 43]: 4環，地屬性魔法。

笔记 [前往页面 43]: 轟轟轟


20220518_194550_1150

笔记 [前往页面 44]: 雙重彈射器!
(Double Catapult)
（雙重彈射器! 字體放大）


20220518_194550_1151

笔记 [前往页面 45]: 咚咚咚咚


20220518_194550_1152

笔记 [前往页面 46]: 咚


20220518_194550_1153

笔记 [前往页面 47]: 吼嗚...
（字體特殊處理）

笔记 [前往页面 47]: 對不起...讓妳這麼累。


20220518_194550_1154

笔记 [前往页面 48]: 咻

笔记 [前往页面 48]: 嗚


20220518_194550_1155

笔记 [前往页面 49]: 嗚

笔记 [前往页面 49]: 雙重燃燒砲彈!
(Double Blaze Shell)
（雙重燃燒砲彈! 字體放大+變色）


20220518_194550_1156

笔记 [前往页面 50]: 嘩啊


20220518_194550_1157

笔记 [前往页面 51]: 轟

笔记 [前往页面 51]: 隆


20220518_194550_1158

笔记 [前往页面 52]: 隆

笔记 [前往页面 52]: 隆

笔记 [前往页面 52]: 隆


20220518_194550_1159

笔记 [前往页面 53]: 這樣就準備完成了。


20220518_194550_1160

笔记 [前往页面 54]: 這一擊就要決定勝負了!

笔记 [前往页面 54]: 滴

笔记 [前往页面 54]: 答


20220518_194550_1163

笔记 [前往页面 57]: 嚴寒的主人，伊米爾。


20220518_194550_1164

笔记 [前往页面 58]: 請容許我借用你的力量吧!


20220518_194550_1165

笔记 [前往页面 59]: 咻嗚嗚

笔记 [前往页面 59]: 嗚嗚


20220518_194550_1166

笔记 [前往页面 60]: 小規模...
(Minor-Force)


20220518_194550_1167

笔记 [前往页面 61]: 暴風雪!
(Blizzard)
（暴風雪! 字體放大＋變色）

笔记 [前往页面 61]: 咻啊


20220518_194550_1168

笔记 [前往页面 62]: 啊啊


20220518_194550_1169

笔记 [前往页面 63]: 哐哐


20220518_194550_1170

笔记 [前往页面 64]: 哐哐


20220518_194550_1171

笔记 [前往页面 65]: 上吧，密特拉!


20220518_194550_1172

笔记 [前往页面 66]: 咚咚


20220518_194550_1173

笔记 [前往页面 67]: 融合魔法!
(Fusion-Magic)


20220518_194550_1174

笔记 [前往页面 68]: 嘩嘩


20220518_194550_1175

笔记 [前往页面 69]: 嘩嘩嘩嘩


20220518_194550_1176

笔记 [前往页面 70]: 火山砲彈!
(Volcanic Shell)
（火山砲彈! 字體放大+變色）


20220518_194550_1177

笔记 [前往页面 71]: 哐啊啊啊


20220518_194550_1182

笔记 [前往页面 76]: 哐


20220518_194550_1183

笔记 [前往页面 77]: 啊


20220518_194550_1186

笔记 [前往页面 80]: 咻嗚

笔记 [前往页面 80]: 呃啊啊...!


20220518_194550_1187

笔记 [前往页面 81]: 咚咚咚咚


20220518_194550_1189

笔记 [前往页面 83]: 我已經到極限了...

笔记 [前往页面 83]: 呼

笔记 [前往页面 83]: 呼


20220518_194550_1190

笔记 [前往页面 84]: 沒辦法再打了。

笔记 [前往页面 84]: 萬一這一擊也無法打倒西爾維婭的話，


20220518_194550_1191

笔记 [前往页面 85]: 我就輸了。
（字體放大）


20220518_194550_1192

笔记 [前往页面 86]: 咻嗚嗚嗚

笔记 [前往页面 86]: 該死，沒有打倒她嗎...?


20220518_194550_1193

笔记 [前往页面 87]: 喂，你...


20220518_194550_1194

笔记 [前往页面 88]: 你說你的名字叫什麼?


20220518_194550_1195

笔记 [前往页面 89]: 泰奧多爾...米勒。


20220518_194550_1196

笔记 [前往页面 90]: 我可以叫你泰奧就好嗎?


20220518_194550_1197

笔记 [前往页面 91]: 隨便啦...


20220518_194550_1198

笔记 [前往页面 92]: 我連講話都快使不上力了...

笔记 [前往页面 92]: 泰奧，


20220518_194550_1199

笔记 [前往页面 93]: 下次...

笔记 [前往页面 93]: 再一起...


20220518_194550_1200

笔记 [前往页面 94]: 玩吧...

笔记 [前往页面 94]: 啪啊


20220518_194550_1201

笔记 [前往页面 95]: 咚


20220518_194550_1202

笔记 [前往页面 96]: 倒地


20220518_194550_1203

笔记 [前往页面 97]: 學生淘汰賽的結果已經出爐!


20220518_194550_1204

笔记 [前往页面 98]: 這次的學生淘汰賽優勝者是132號!


20220518_194550_1205

笔记 [前往页面 99]: 赤色魔塔的高級魔法師...
（字體放大）


20220518_194550_1206

笔记 [前往页面 100]: 文斯．海德爾的徒弟!
（字體放大）


20220518_194550_1207

笔记 [前往页面 101]: 哇啊啊啊


20220518_194550_1208

笔记 [前往页面 102]: 泰奧多爾．米勒選手!
（字體放大＋變色處理）


20220518_194550_1209

笔记 [前往页面 103]: 哈哈...

笔记 [前往页面 103]: 疲累


20220518_194550_1210

笔记 [前往页面 104]: 我贏了!


20220518_194550_1213

笔记 [前往页面 107]: 啪啊

笔记 [前往页面 107]: 下回待續
    
    `
}