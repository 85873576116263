<script lang="jsx">
import { mapActions, mapGetters, mapState } from 'vuex'
import VirtualList from 'vue-virtual-scroll-list'
import Item from './Item.vue'
import DocsifyEditor from '@/views/documention/docsify-editor'
import { getAction, postAction, deleteAction } from '@/api/manage'
import mock from '@/views/tool/mock.js'
import { axios } from '@/utils/request'
import { decodeWord, encodeWord } from '@/utils/util.js'
import { handleArrayParams } from '@/utils/util'
console.log({ mock })
const typeWordNotContains = '不在台湾繁体字库中'
const typeWordContains = '在韩翻繁的屏蔽词中'
export default {
  components: {
    VirtualList,
    DocsifyEditor
  },
  data() {
    return {
      logo: '../../assets/favicon.png',
      inputText: '',
      customStyle: {
        background: '#f7f7f7',
        marginBottom: 0,
        overflow: 'hidden'
      },
      itemComponent: Item,
      activeKey: [],
      dataList: [],
      itemHeight: 31,
      editor: null,
      inspecting: false,
      vocList: [],
      selectValues: [],
      deleArr: []
    }
  },
  computed: {
    chineseLength() {
      return this.inputText.match(/[\u4E00-\u9FA5]/g)?.length || 0
    }
  },
  beforeCreate() {
    document.title = '质检大师（繁）'
  },
  beforeDestroy() {
    // this.editor?.destroy() // 组件销毁时，及时销毁编辑器
  },
  created() {
    this.inspect()
    this.getBackGround()
    this.qualityInspection()
    /* const words = Array.from({ length: 100000 }, (_, index) => ({ uid: index, text: `${index + 1}. 麽` }))
    this.dataList = [{ title: `xx词 (${words.length})`, words },
    { title: `xx词 (${words.length})`, words },
    { title: `xx词 (${words.length})`, words },
    { title: `xx词 (${words.length})`, words },
    ]
    console.log({ 'this.dataList': this.dataList });
    this.activeKey = this.dataList.map((_, key) => String(key)) */
  },
  watch: {
    inputText() {
      console.log({ 'this.inputText': this.inputText })
      // this.$refs.editor?.setHtml(this.inputText)
    }
  },
  mounted() {
    this.timerFun()
  },
  methods: {
    ...mapActions(['Logout']),
    async getSelectValue() {
      const res = await getAction('/fontQueryCondition', { domain: 'TRADITIONAL', type: 'FILTER' })
      if (res.data.length) {
        this.selectValues = JSON.parse(res.data[res.data.length - 1].content)
        this.deleArr = res.data.map(item => item.id)
        await postAction('/qualityInspection/blackWhiteSync', {
          "ids": this.selectValues,
          "uid": this.userInfo.id
        })
      }
    },
    async qualityInspection() {
      const res = await getAction('qualityInspection/guides?inspectType=TRADITIONAL')
      if (res.code == 200) {
        this.vocList = res.data
        this.getSelectValue()
      } else {
        this.$message.error(res.msg)
      }
    },
    async selectChange(value) {
      this.selectValues = value
      const data = [
        {
          name: 'TRADITIONAL',
          content: JSON.stringify(value),
          domain: 'TRADITIONAL',
          type: 'FILTER'
        }
      ]
      await deleteAction(
        '/fontQueryCondition?' + new URLSearchParams(handleArrayParams({ ids: this.deleArr })).toString()
      )
      await postAction('/fontQueryCondition', data)
    },
    timerFun() {
      this.stopTimer = false
      let myTimer = setInterval(async () => {
        // 停止定时器
        if (this.stopTimer == true) {
          clearInterval(myTimer)
          return
        }
        await getAction('/sys/checkLogin')
      }, 6000)
    },
    async getBackGround() {
      try {
        const res = await getAction('/theme/query')
        if (res.msg == 'SUCCESSFUL') {
          res.data.forEach(item => {
            if (item.type == 'LOGO') {
              this.logo = item.value
            }
          })
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        console.log(err)
      }
    },
    logout() {
      console.log({ 'this.ls': this.ls })
      this.$confirm({
        title: '提示',
        content: '退出 ?',
        onOk: () => this.Logout({}).then(() => this.$router.push({ path: '/user/login' }))
      })
    },
    inspect() {
      this.inspecting = true
      this.activeKey = []
      this.dataList = this.dataList.map(({ title, maxHeight }) => ({ title, maxHeight, words: [] }))
      const words = encodeWord(
        this.inputText
          .replaceAll(/(<\/?u.*?>)|(<\/?em.*?>)/g, '')
          .replaceAll('&nbsp;', ' ')
          .replaceAll(/(<\/?br.*?>)/g, '\r\n')
      )
      console.log('inspect', { words })

      // 发起请求
      axios
        .post('/qualityInspection/replace', {
          words,
          ids: this.selectValues
        })
        .then(({ data: { result, words } }) => {
          this.inspecting = false
          this.dataList = Object.entries(result).map(([title, ws]) => {
            const wds = ws.map((text, uid) => ({ uid, text, onClick: e => this.onItemClick(e, ws, text) }))
            return { title, maxHeight: 0, words: wds }
          })
          this.activeKey = this.dataList
            .map(({ words }, idx) => (words.length ? idx : undefined))
            .filter(i => i !== undefined)
          console.log({ 'this.dataList': this.dataList })
          console.log({ 'this.activeKey': this.activeKey })
          console.log({ 'inspect over': this.inputText })
          console.log({ 'this.$refs.editor': this.$refs.editor })
          // this.inputText = words
          this.$refs.editor?.setHtml(decodeWord(words))
          // this.editor?.setHtml(words)
          // this.activeKey = this.dataList.map((_, key) => String(key))
        })
        .catch(e => (this.inspecting = false))
    },
    onItemClick(e, words, { value, times }) {
      value = decodeWord(value)
      // 右侧虚拟列表每一项被点击
      console.log('onItemClick', { e, words, data: { value, times } })
      // 匹配次数(匹配节点下标)
      let matchIndex = -1
      for (let [idx, node] of document.querySelectorAll('span[data-slate-node=text]').entries()) {
        // 遍历所有node节点
        // 获取span节点内的文本节点
        if (!node.querySelector('u') && !node.querySelector('em')) {
          continue
        }
        const textNode = node?.querySelector('span[data-slate-string=true]')?.firstChild
        if (!textNode) {
          continue
        }
        // 如果包含，则记录匹配次数
        let index
        do {
          index = textNode.textContent.indexOf(value, index + value.length)
          console.log({ 'textNode.textContent': textNode.textContent })
          console.log({ index })
          if (index > -1) {
            matchIndex++
            if (matchIndex == times) {
              // 日志一下编辑器
              console.log({ 'this.$refs.editor': this.$refs.editor })
              // 滚动到对应位置
              console.log({ node })
              this.$refs.editor?.scrollToElem(node.id)
              //获取selection对象
              const selection = window.getSelection()
              // 移除所有选择区域
              selection.removeAllRanges()
              // 创建范围对象
              const range = document.createRange()
              // 设置范围的起点为文字在内容中出现的下标
              const offsetStart = index
              range.setStart(textNode, offsetStart)
              // 设置范围的终点为起点+内容长度
              range.setEnd(textNode, offsetStart + value.length)
              // 光标选择范围
              selection.addRange(range)
              console.log({ textNode, selection, range })
              return
            }
          }
        } while (index > -1)
      }
    },
        recognition(){
      if(!this.selectValues.length){
        this.$message.error('请选择词汇')
        return
      }
      this.inspect()
    },
    collapseChange(key) {
      this.activeKey = key
      console.log({ 'this.activeKey': this.activeKey })
      // 计算高度
      console.log({ 'this.dataList[key]': this.dataList[key] })
    },
    inputTextChange(inputText, callback) {
      console.log('inputTextChange', { inputText, callback })
      this.inputText = inputText
      callback?.()
    },
    goToHome() {
      window.location.href = '/'
    }
  },
  render() {
    return (
      <div>
        <img class="logo" vOn:click={this.goToHome} src={this.logo} alt="" style="min-width:32px;height:32px;" />
        <a-page-header
          title="质检大师（繁）"
          style={{ border: '1px solid rgb(235, 237, 240)', userSelect: 'none' }}
          scopedSlots={{
            extra: () => (
              <a-button loading={this.inspecting} vOn:click={this.logout} vOn:dblclick_native_stop={() => { }}>
                退出
              </a-button>
            )
          }}
        />
        <a-row class="container" type="flex" justify="center">
          <a-col span="12">
            <a-card hoverable>
                        <span slot='title'>录入区域
            <span style='font-size:12px;color:rgba(0,0,0,0.25);margin-left:5px'>若包含半角的'、"、:均不会提示</span>
            </span>
              <div class="input-text-total" slot="extra">
                <div class='input-text-header'>
                  <a-select showArrow maxTagCount={1} dropdownClassName='dict-select-item' value={this.selectValues} mode='multiple' vOn:change={this.selectChange} placeholder='请选择词汇'
                    class="dict-select">
                    {
                      this.vocList?.map(item => {
                        return (
                          <a-select-option key={item.guideId} value={item.guideId}>{item.guideName}</a-select-option>
                        )
                      })
                    }
                  </a-select>
                  <div>
                    共{this.chineseLength}字
                  </div>
                </div>
              </div>
              <docsify-editor
                clearFormatOnPaste
                hideToolBar
                ref="editor"
                editorMode="simple"
                vOn:input={this.inputTextChange}
              />
              <div class="clear-btn-view" vOn:click={() => this.$refs.editor?.clear()}>
                <a-icon class="clear-btn" type="close-circle" />
              </div>
            </a-card>
          </a-col>
          <a-col span="12">
            <a-card
              title={
                <a-button loading={this.inspecting} vOn:click={this.recognition} vOn:dblclick_native_stop={() => { }}>
                  识别
                </a-button>
              }
              hoverable
              class="inspection"
              scopedSlots={{
                extra: () => (
                  <div>
                    <div class="inspection-total">
                      共
                      <span not-contains-dictionary>
                        {this.dataList.filter(({ title }) => title === '非字库').flatMap(({ words }) => words).length}
                      </span>
                      处{typeWordNotContains}
                    </div>
                    <div class="inspection-total">
                      共
                      <u contains-block>
                        {this.dataList.filter(({ title }) => title !== '非字库').flatMap(({ words }) => words).length}
                      </u>
                      处{typeWordContains}
                    </div>
                  </div>
                )
              }}
            >
              {this.dataList.length ? (
                <a-collapse
                  ref="collapse"
                  activeKey={this.activeKey}
                  vOn:change={this.collapseChange}
                  scopedSlots={{
                    expandIcon: ({ isActive }) => <a-icon type="caret-right" rotate={Number(isActive) * 90} />
                  }}
                >
                  {this.dataList.map((item, key) => (
                    <a-collapse-panel
                      key={String(key)}
                      header={
                        item.title +
                        String(item.words?.length || '')
                          .split()
                          .filter(i => i)
                          .flatMap(i => ['(', i, ')'])
                          .join('')
                      }
                      style={this.customStyle}
                      disabled={!item.words?.length}
                    >
                      <virtual-list
                        class="virtual-list"
                        style={{
                          maxHeight:
                            item.maxHeight ||
                            `calc((100vh - 210px - ${34 * this.dataList.length}px) / ${this.activeKey.length})`
                        }}
                        dataKey="uid"
                        dataSources={item.words}
                        dataComponent={this.itemComponent}
                      />
                    </a-collapse-panel>
                  ))}
                </a-collapse>
              ) : (
                <div></div>
              )}
            </a-card>
          </a-col>
        </a-row>
      </div>
    )
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/inspect.less';

body {
  margin: 0;
  padding: 0;
}
</style>
